.sea-modal-box {
    /* --backdrop-opacity: 0.4; */
    font-size: 13px;
    line-height: 1.5em;
}
.sea-modal-box::part(content) {
    position: absolute;
    border-radius: 4px;
    --max-width: calc(100vw - 40px);
    --max-height: calc(100vh - 40px);
}

.sea-modal-box::part(backdrop) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.sea-modal-box::part(content) {
    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
    border-radius: 8px;
}

.sea-modal-box .modal-box-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* min-height: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

