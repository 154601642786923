.sea-divisions-modal .modal-box-container {
    justify-content: normal;
}

.sea-division-parent {
    padding: 0px 0px 0px 33px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.sea-divisions-modal .sea-division-parent {
    padding: 0px 0px 4px 40px;
}

.sea-division-options {
    padding: 4px 8px;
    width: 350px;
    max-width: 90vw;
}

.sea-division-option .check {
    padding: 3px 4px 0px 0px;
    height: 40px;
}

.sea-division-option .name {
    padding: 8px 8px 8px 4px;
    font-size: 15px;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: background-color 0.2s;
}

.sea-division-option .name.no-access {
    pointer-events: none;
    opacity: 0.33;
}

.sea-division-option .name:hover, .modal-box-container .sea-division-option .name.hover:hover {
    background-color: #f5f5f5;
}

.sea-division-option .folder-icon {
    padding: 7px 2px 0px 4px;
    color: var(--icon-grey);
    transition: all 0.3s ease-in-out;
}

.sea-division-option .folder-icon.opened {
    transform: rotate(0deg) translate(-1px, 0px);
}
.sea-vessels-modal .sea-division-option .folder-icon.opened {
    transform: rotate(0deg) translate(-3px, 2px);
}
.sea-division-option .folder-icon.closed {
    transform: rotate(-90deg);
}


.sea-select-multi-box .sea-division-option .name {
    background-color: transparent;
    transition: none;
}
.sea-select-multi-box .sea-division-option .name:hover {
    background-color: transparent;
}
